/**
 * @fileOverview Main application component loaded from index.js.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { detect } from "detect-browser";

// VT Touch
import NavBar from "./components/NavBar";
import Home from "./components/home";
import Login from "./components/login";
import Logout from "./components/logout";
import Profile from "./components/profile";
import Users from "./components/Users";
import RegisterUser from "./components/RegisterUser";
import RegisterPatient from "./components/RegisterPatient";
import Patients from "./components/Patients";
import DeviceLogUploadWrapper from "./components/upload/DeviceLogUploadWrapper";
import TechnicianPassword from "./components/TechnicianPassword";
import ProtectedRoute from "./components/common/protectedRoute";
import auth from "./services/authService";

class App extends Component {
  state = {
    user: null
  };

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;
    const browser = detect();

    console.log("Detected browser: " + browser.name);
    switch (browser && browser.name) {
      case "chrome":
        return (
          <React.Fragment>
            <NavBar user={this.state.user} />
            <div className="content">
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <ProtectedRoute path="/profile" component={Profile} />
                <ProtectedRoute path="/users" component={Users} />
                <ProtectedRoute path="/registeruser" component={RegisterUser} />
                <ProtectedRoute path="/patients" component={Patients} />
                <ProtectedRoute path="/registerpatient" component={RegisterPatient} />
                <ProtectedRoute path="/devicelogupload" component={DeviceLogUploadWrapper} />
                <ProtectedRoute path="/technicianPassword" component={TechnicianPassword} />
                <Route path="/" exact component={Home} />
              </Switch>
            </div>
          </React.Fragment>
        );
      case "firefox":
        return `Unsupported browser(${browser.name}): Please use Chrome!`;
      case "edge":
        return `Unsupported browser(${browser.name}): Please use Chrome!`;
      default:
        return "Unsupported browser(unknown): Please use Chrome!";
    }
  }
}

export default App;
