/**
 * @fileOverview The home page.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React from "react";

// VT Touch
import "../css/anotherTest.css";
import frontPage from "../Test-01.png";

const Home = () => {
  return (
    <div className="front">
      <div className="front-page">
        <img src={frontPage} width="100%" height="80%" />
      </div>
    </div>
  );
};

export default Home;
