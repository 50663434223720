/**
 * @fileOverview Provides general HTTP services.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

import axios from "axios";
import { toast } from "react-toastify";

// Todo: The below should be extended at some point to a logging server
// to handle a variety of client server interactions for logging purposes.

// Install a general interceptor.
axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.log("HTTP response error: ", error);
    toast("An unexpected error has occured!");
  }

  return Promise.reject(error);
});

function setJWT(jwt) {
  // Set the header for logged in users.
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

// Shield Axios behind the below exports.
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJWT
};
