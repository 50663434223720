/**
 * @fileOverview Displays patient information.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

import axios from "axios";
import { ExportToCsv } from 'export-to-csv';
import MaterialTable from 'material-table';
import React from "react";

import SimpleDialogDemo from "./DeviceLog";
import { configuration } from "../globals";

const base_url = `${configuration.vt_server_address}:${configuration.vt_server_port}/api/devices`;

const SWIFT_OFFSET = Date.UTC(2001, 0, 1);

/**
 * Convert a Swift date float to a string representing the date
 *
 * @param {number} swiftDate - The Swift date as a floating-point number
 * @param {string} [defaultString='Invalid date'] - The default string to use if the date is invalid
 * @returns {string} The date string
 */
const dateStringFromSwift = (swiftDate, defaultString = 'Invalid date') => {
  if (swiftDate === -63114076800) {
    return defaultString;
  }
  return new Date(SWIFT_OFFSET + swiftDate * 1000).toLocaleString();
}

export default function Patient(props) {
  const classes = null; // Not adding any css at the moment.
  const [deviceLogs, setDeviceLogs] = React.useState([]);

  const columns = [
    {
      title: 'Date Uploaded',
      field: 'date',
      type: 'string',
      defaultSort: 'desc',
      render: (f) => new Date(f.date).toLocaleString(),
    },
    { title: 'Session', field: 'log.session' },
    { title: 'Block', field: 'log.block' },
    {
      title: 'Stim Start',
      field: 'log.stimStart',
      type: 'number',
      render: (f) => dateStringFromSwift(f.log.stimStart, 'Stimulation not started'),
    },
    {
      title: 'Stim End',
      field: 'stimEnd',
      type: 'number',
      render: (f) => dateStringFromSwift(f.log.stimEnd, 'Stimulation not ended'),
    },
    { title: 'Stim End Code', field: 'log.stimEndCode' },
    {
      title: 'Actions',
      render: (deviceLog) => (
        <SimpleDialogDemo logID={deviceLog._id} deviceLog={JSON.stringify(deviceLog.log)} />
      ),
      cellStyle: {
        whiteSpace: 'nowrap',
      }
   }
  ];

  React.useEffect(() => {
    async function fetchData() {
      const { data: deviceLogs } = await axios.get(
        `${base_url}/${props.patient.medicalID}`
      );
      deviceLogs.forEach((log) => {
        log.log = JSON.parse(log.log);
      })
      console.log(deviceLogs);
      setDeviceLogs(deviceLogs);
    }

    if (props.patient) {
      fetchData();
    }
  }, [props.patient]);

  if (!props.patient) {
    return null;
  }

  if (props.selectedAction === "show_logs") {
    return (
      <MaterialTable
        title="Device Logs"
        columns={columns}
        data={deviceLogs}
        options={{
          paging: false,
          padding: 'dense',
          exportButton: true,
          exportCsv: (columns, data) => {
            if (data.length === 0) {
              return;
            }
            let exportData = [...data];
            const removeCommas = (string) => string.replace(',', '');
            const transformSwiftDates = (swiftDates) => {
              if (swiftDates && swiftDates.length > 0 ) {
                return swiftDates
                  .map((sDate) => removeCommas(dateStringFromSwift(sDate)))
                  .join(',');
              } else {
                return '';
              }
            };
            exportData = exportData.map((d) => ({
              'Date Uploaded': removeCommas(new Date(d.date).toLocaleString()),
              'Session': d.log.session,
              'Block': d.log.block,
              'Stim Start': removeCommas(dateStringFromSwift(d.log.stimStart, 'Stimulation not started')),
              'Stim End': removeCommas(dateStringFromSwift(d.log.stimEnd, 'Stimulation not ended')),
              'Stim End Code': d.log.stimEndCode,
              'Pause Start Times': `${transformSwiftDates(d.log.pauseStart)}`,
              'Pause End Times': `${transformSwiftDates(d.log.pauseEnd)}`,
              'Connection Lost Times': `${transformSwiftDates(d.log.devConnLost)}`,
              'Connect Restore Times': `${transformSwiftDates(d.log.devConnRest)}`,
            }));
            const csvExporter = new ExportToCsv({ useKeysAsHeaders: true });
            csvExporter.generateCsv(exportData);
          }
        }}
      />
    )
  }
}
