/**
 * @fileOverview Displays a list of users.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React, { Component } from "react";

// VT Touch
import UsersTable from "./UsersTable";
import User from "./User";

class Users extends Component {
  state = {
    selectectedUser: null
  };

  render() {
    return (
      <div className="container">
        <br />
        <div>
          <UsersTable handleUserSelect={this.handleSelectUser} />
        </div>
        <div>
          <User user={this.state.selectectedUser} />
        </div>
      </div>
    );
  }

  handleSelectUser = user => {
    console.log("Details requested for user: " + user);

    this.setState({
      selectectedUser: user
    });
  };
}

export default Users;
