/**
 * @fileOverview Provides a device log dialog to display and save device logs.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React from "react";
import PropTypes from "prop-types";
import fileDownload from "js-file-download";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import { blue } from "@material-ui/core/colors";

// Setup the dialog title.
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

// The dialog.
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  }
});

function DeviceLogDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    selectedValue,
    logID,
    deviceLogs,
    deviceLogString,
    ...other
  } = props;

  function handleClose() {
    onClose(selectedValue);
  }

  function handleListItemClick(value) {
    onClose(value);
  }

  function saveLog() {
    console.log("Saving log...");
    fileDownload(getDeviceLogString(deviceLogs, logID), `Device_${logID}.txt`);

    // This is no longer used. Keeping it because it's a cool regexp! :-)
    /* fileDownload(
      JSON.stringify(deviceLogs).replace(/\",\"/g, "\r\n") + "\r\n",
      `Device_${logID}.txt`
    ); */
  }

  if (!props.open) {
    return null;
  } else {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth="md"
        {...other}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Device Log
        </DialogTitle>
        <pre>{getDeviceLogString(deviceLogs, logID)}</pre>
        <DialogActions>
          <Button variant="outlined" onClick={saveLog} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function getDeviceLogString(deviceLogs, logID) {
  let deviceLogString = `Log ID: ${logID}\n`;
  deviceLogs.forEach(element => {
    switch (element.type) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 10:
      case 11:
        deviceLogString =
          deviceLogString +
          `${element.displayName}: ${element.displayValues[0]}\n`;
        break;
      case 6:
        deviceLogString = deviceLogString + "Pause Start:\n";
        if (element.displayValues.length === 0) {
          deviceLogString = deviceLogString + `\tPatient did not Pause\n`;
        } else {
          element.displayValues.forEach(pauseElement => {
            deviceLogString = deviceLogString + `\t${pauseElement}\n`;
          });
        }
        break;
      case 7:
        deviceLogString = deviceLogString + "Pause End:\n";
        if (element.displayValues.length === 0) {
          deviceLogString = deviceLogString + `\tPatient did not End Pause\n`;
        } else {
          element.displayValues.forEach(pauseElement => {
            deviceLogString = deviceLogString + `\t${pauseElement}\n`;
          });
        }
        break;
      case 8:
        deviceLogString = deviceLogString + "Device Connection Lost:\n";
        if (element.displayValues.length === 0) {
          deviceLogString =
            deviceLogString + `\tDevice did not Lose Connection\n`;
        } else {
          element.displayValues.forEach(pauseElement => {
            deviceLogString = deviceLogString + `\t${pauseElement}\n`;
          });
        }
        break;
      case 9:
        deviceLogString = deviceLogString + "Device Connection Restored:\n";
        if (element.displayValues.length === 0) {
          deviceLogString =
            deviceLogString + `\tDevice did not Restore Connection\n`;
        } else {
          element.displayValues.forEach(pauseElement => {
            deviceLogString = deviceLogString + `\t${pauseElement}\n`;
          });
        }
        break;
      default:
        break;
    }
  });

  return deviceLogString;
}

// Type checking.
DeviceLogDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string
};

// Dialog export.
// This is basically a button, and the dialog will display if the
// button is clicked.
export default function DeviceLogDialogExport(props) {
  const [open, setOpen] = React.useState(false);
  const timeZoneOffset = 0; //new Date().getTimezoneOffset() * 60 * 1000;

  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = value => {
    setOpen(false);
  };

  function toReadableDate(dateString) {
    const appleTimeAdjustment = 978307200000;
    const appleTime = dateString.split(".")[0];
    return `${new Date(
      parseInt(
        parseInt(appleTime * 1000) + appleTimeAdjustment - timeZoneOffset
      )
    ).toLocaleString()} (${dateString})`;
  }

  const logFromString = JSON.parse(props.deviceLog);
  const deviceLogs = [
    {
      displayName: "Application Start Time",
      type: 0,
      displayValues: ["Unknown"]
    }, //0
    { displayName: "Patient ID", type: 1, displayValues: ["Unknown"] }, //1
    { displayName: "Device ID", type: 2, displayValues: ["Unknown"] }, //2
    { displayName: "Session", type: 3, displayValues: ["Unknown"] }, //3
    { displayName: "Block", type: 4, displayValues: ["Unknown"] }, //4
    {
      displayName: "Stim Start",
      type: 5,
      displayValues: ["Stimulation Not Started"]
    }, //5
    { displayName: "Pause Start", type: 6, displayValues: [] }, //6
    { displayName: "Pause End", type: 7, displayValues: [] }, //7
    { displayName: "Device Conn Lost", type: 8, displayValues: [] }, //8
    { displayName: "Device Conn Restored", type: 9, displayValues: [] }, //9
    {
      displayName: "Stim End",
      type: 10,
      displayValues: ["Stimulation Did Not End"]
    }, //10
    { displayName: "Stim End Code", type: 11, displayValues: ["Unknown"] } //11
  ];

  // Build a string for display and saving.
  // Note: For now, this is what we'll use for the most part.
  let deviceLogString = "";

  for (let property in logFromString) {
    if (property === "appStartTime") {
      if (logFromString[property] !== -63114076800) {
        deviceLogs[0].displayValues = [
          toReadableDate(logFromString[property].toString())
        ];
      }
    } else if (property === "ptid") {
      deviceLogs[1].displayValues = [logFromString[property].toString()];
    } else if (property === "devid") {
      deviceLogs[2].displayValues = [logFromString[property].toString()];
    } else if (property === "session") {
      deviceLogs[3].displayValues = [logFromString[property].toString()];
    } else if (property === "block") {
      deviceLogs[4].displayValues = [logFromString[property].toString()];
    } else if (property === "stimStart") {
      if (logFromString[property] !== -63114076800) {
        deviceLogs[5].displayValues = [
          toReadableDate(logFromString[property].toString())
        ];
      }
    } else if (property === "pauseStart") {
      for (const index in logFromString[property]) {
        deviceLogs[6].displayValues.push(
          toReadableDate(logFromString[property][index].toString())
        );
      }
    } else if (property === "pauseEnd") {
      for (const index in logFromString[property]) {
        deviceLogs[7].displayValues.push(
          toReadableDate(logFromString[property][index].toString())
        );
      }
    } else if (property === "devConnLost") {
      for (const index in logFromString[property]) {
        deviceLogs[8].displayValues.push(
          toReadableDate(logFromString[property][index].toString())
        );
      }
    } else if (property === "devConnRest") {
      for (const index in logFromString[property]) {
        deviceLogs[9].displayValues.push(
          toReadableDate(logFromString[property][index].toString())
        );
      }
    } else if (property === "stimEnd") {
      if (logFromString[property] !== -63114076800) {
        deviceLogs[10].displayValues = [
          toReadableDate(logFromString[property].toString())
        ];
      }
    } else if (property === "stimEndCode") {
      deviceLogs[11].displayValues = [logFromString[property].toString()];
    }
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Show Log
      </Button>
      <DeviceLogDialog
        open={open}
        onClose={handleClose}
        logID={props.logID}
        deviceLogs={deviceLogs}
        deviceLogString={deviceLogString}
      />
    </div>
  );
}
