/**
 * @fileOverview Wrapper around device log upload.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React, { Component } from "react";

// VT Touch
import "../../css/DeviceLogUploadWrapper.css";
import DeviceLogUpload from "./DeviceLogUpload";

class DeviceLogUploadWrapper extends Component {
  render() {
    return (
      <div>
        <div className="FileUpload">
          <div>
            {/* Not particularly sure I like passing all the props here! */}
            <DeviceLogUpload {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceLogUploadWrapper;
