/**
 * @fileOverview The login page.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// VT Touch
import "../css/signin.css";
import logo from "../Synergic Final logo.svg";
import auth from "../services/authService";

/**
 * Login component.
 */
class Login extends Component {
  state = {
    account: { username: "", password: "" },
    errors: null
  };

  componentDidMount() {}

  handleSubmit = async e => {
    e.preventDefault();

    const { account } = this.state;
    try {
      await auth.login(account.username, account.password);
      window.location = "/";
      //this.props.history.push("/"); // This does not seem to force a reload.
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const account = { ...this.state.account };
    account[input.name] = input.value;
    this.setState({ account });
  };

  render() {
    const { account } = this.state;

    // Check to see if the user is already logged in.

    return (
      <div className="sign-in">
        <ToastContainer />
        <form className="form-signin" onSubmit={this.handleSubmit}>
          <img className="mb-4" src={logo} alt="" width="200" height="72" />
          <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
          <label htmlFor="inputEmail" className="sr-only">
            Email address
          </label>
          <input
            value={account.username}
            type="text"
            id="username"
            name="username"
            className="form-control"
            placeholder="Username"
            required
            autoFocus
            onChange={this.handleChange}
          />
          <label htmlFor="inputPassword" className="sr-only">
            Password
          </label>
          <input
            value={account.password}
            type="password"
            id="password"
            name="password"
            className="form-control"
            placeholder="Password"
            required
            onChange={this.handleChange}
          />
          {this.state.errors && (
            <div className="alert alert-danger">Invalid login!</div>
          )}
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Sign in
          </button>
          <p className="mt-5 mb-3 text-muted">&copy; 2019</p>
        </form>
      </div>
    );
  }
}

export default Login;
