/**
 * @fileOverview The logout page.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React, { Component } from "react";

// VT Touch
import auth from "../services/authService";

/**
 * Logout component.
 */
class Logout extends Component {
  componentDidMount() {
    auth.logout();
    window.location = "/";
  }

  render() {
    return null;
  }
}

export default Logout;
