/**
 * @fileOverview The register user page.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React from "react";
import Joi from "joi-browser";

// VT Touch
import Form from "./common/Form";
import { registerUser } from "./../services/users";

class RegisterForm extends Form {
  state = {
    data: {
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      roleID: "1",
      typeID: "1"
    },
    roles: [
      { _id: "1", name: "Clinician" },
      { _id: "2", name: "Admin" },
      { _id: "3", name: "Device" }
    ],
    types: [
      { _id: "1", name: "vt" },
      { _id: "2", name: "bel" }
    ],
    errors: {}
  };

  schema = {
    firstname: Joi.string()
      .required()
      .max(50)
      .label("First Name"),
    lastname: Joi.string()
      .required()
      .max(50)
      .label("Last Name"),
    username: Joi.string()
      .required()
      .min(5)
      .label("Username"),
    password: Joi.string()
      .required()
      .min(8)
      .max(1024)
      .label("Password"),
    roleID: Joi.string()
      .required()
      .label("Role"),
    typeID: Joi.string()
      .required()
      .label("Type")
  };

  doSubmit = async () => {
    const { data } = this.state;

    const role = this.state.roles.find(e => e._id === data.roleID);
    const type = this.state.types.find(e => e._id === data.typeID);

    try {
      const { data: result } = await registerUser(
        data.firstname,
        data.lastname,
        data.username,
        data.password,
        role.name,
        type.name
      );
      window.location = "/users";
      //this.props.history.push("/"); // This does not seem to force a reload.
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.typeID = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="container">
        <p />
        <h4>
          <span className="badge badge-secondary">Register User</span>
        </h4>
        <div className="shadow p-3 mb-3 bg-white rounded">
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("firstname", "First Name")}
            {this.renderInput("lastname", "Last Name")}
            {this.renderInput("username", "Username")}
            {this.renderInput("password", "Password", "password")}
            {this.renderSelect("roleID", "Role", this.state.roles)}
            {this.renderSelect("typeID", "Type", this.state.types)}

            <p />

            {this.renderButton("Register")}
          </form>
        </div>
      </div>
    );
  }
}

export default RegisterForm;
