/**
 * @fileOverview Provides a input field.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React from "react";
import { Route, Redirect } from "react-router-dom";

// VT Touch
import auth from "../../services/authService";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const user = auth.getCurrentUser();
        if (!user) return <Redirect to="/login" />;
        return Component ? <Component {...props} user={user} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
