/**
 * @fileOverview Displays a list of users.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React, { Component } from "react";
import axios from "axios";

// VT Touch
import { configuration } from "../globals";

const base_url = `${configuration.vt_server_address}:${configuration.vt_server_port}/api/users`;

class UsersTable extends Component {
  state = {
    users: [],
    selectectedUser: "No File Selected",
    selectectedUserIndex: -1,
    handleUserSelect: this.props.handleUserSelect
  };

  async componentDidMount() {
    const { data: users } = await axios.get(base_url);

    this.setState({ users });
  }

  render() {
    return (
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Role</th>
          </tr>
        </thead>
        <tbody>
          {this.state.users.map((user, index) => this.getTableRow(user, index))}
        </tbody>
      </table>
    );
  }

  getTableRow(user, index) {
    return (
      <tr
        key={index}
        className={
          this.state.selectectedUserIndex === index ? "table-warning" : null
        }
        onClick={() => {
          console.log(
            "Details requested for user: " +
              user.username +
              " at index: " +
              index
          );

          this.setState({
            selectectedUserIndex: index,
            selectectedUser: user.username
          });

          // The above state change
          this.state.handleUserSelect(user);
        }}
      >
        <td>{`${user.firstname} ${user.lastname}`}</td>
        <td>{user.role}</td>
      </tr>
    );
  }
}

export default UsersTable;
