/**
 * @fileOverview Displays user information.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React, { Component } from "react";

// VT Touch
import noUser from "../NoUser.png";

class User extends Component {
  state = {
    user: this.props.user,
    userIndex: -1,
    userInformation: { size: -1, date: "00-00-0000" }
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.user && !this.props.user) {
      return;
    } else if (!prevProps.user && this.props.user) {
      this.setState({ user: this.props.user });
    } else if (prevProps.user && !this.props.user) {
      this.setState({ user: this.props.user });
    } else if (prevProps.user.username !== this.props.user.username) {
      this.setState({ user: this.props.user });
    }
  }

  render() {
    if (!this.state.user) {
      return (
        <div className="user-profile">
          <div className="box one" />
          <div className="shadow two p-3 mb-3 bg-white rounded">
            <div className="card" style={{ width: "18rem" }}>
              <img className="card-img-top" src={noUser} alt="Card image cap" />
              <div className="card-body">
                <p className="card-text">No user selected!</p>
              </div>
            </div>
          </div>
          <div className="box one" />
        </div>
      );
    }

    return (
      <div className="user-profile">
        <div className="box one" />
        <div className="shadow two p-3 mb-3 bg-white rounded">
          <div className="card" style={{ width: "18rem" }}>
            <img className="card-img-top" src={noUser} alt="Card image cap" />
            <div className="card-body">
              <p className="card-text">
                <ul>
                  <li>
                    <b>Name:</b>{" "}
                    {`${this.state.user.firstname} ${this.state.user.lastname}`}
                  </li>
                  <li>
                    {" "}
                    <b>Username: </b>
                    {this.state.user.username}
                  </li>
                  <li>
                    {" "}
                    <b>Role: </b>
                    {this.state.user.role}
                  </li>
                  <li>
                    {" "}
                    <b>Type: </b>
                    {this.state.user.userType}
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="box one" />
      </div>
    );
  }
}

export default User;

/*
return (
      <div className="shadow p-3 mb-3 bg-white rounded">
        <form>
          <div className="form-group row">
            <label htmlFor="inputFirstName" className="col-sm-2 col-form-label">
              First Name
            </label>
            <div className="col-sm-10">
              <input
                className="form-control"
                id="inputFirstName"
                placeholder={this.state.user.firstname}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputLastName" className="col-sm-2 col-form-label">
              First Name
            </label>
            <div className="col-sm-10">
              <input
                className="form-control"
                id="inputFirstName"
                placeholder={this.state.user.firstname}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
              Username
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="staticUsername"
                value={this.state.user.username}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">
              Password
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                className="form-control"
                id="inputPassword"
                placeholder="Password"
              />
            </div>
          </div>
        </form>
      </div>
    );
    */

/* render() {
    return (
      <div>
        {this.state.user && (
          <React.Fragment>
            <table className="table table-hover table-bordered">
              <thead />
              <tbody>
                <tr>
                  <th scope="col" style={{ width: "30%" }}>
                    First Name:
                  </th>
                  <td>{this.state.user.firstname}</td>
                </tr>
                <tr>
                  <th scope="col" style={{ width: "30%" }}>
                    Last Name:
                  </th>
                  <td>{this.state.user.lastname}</td>
                </tr>
                <tr>
                  <th scope="col" style={{ width: "30%" }}>
                    Username:
                  </th>
                  <td>{this.state.user.username}</td>
                </tr>
                <tr>
                  <th scope="col" style={{ width: "30%" }}>
                    Role:
                  </th>
                  <td>{this.state.user.role}</td>
                </tr>
                <tr>
                  <th scope="col" style={{ width: "30%" }}>
                    User Type:
                  </th>
                  <td>{this.state.user.userType}</td>
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        )}
      </div>
    );
  } */

/*
// Demo version!!
<form>
          <div className="form-group row">
            <label
              htmlFor="staticFirstName"
              className="col-sm-2 col-form-label"
            >
              First Name
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="staticFirstName"
                value={this.state.user.firstname}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticLastName" className="col-sm-2 col-form-label">
              Last Name
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="staticLastName"
                value={this.state.user.lastname}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticUsername" className="col-sm-2 col-form-label">
              Username
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="staticUsername"
                value={this.state.user.username}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticRole" className="col-sm-2 col-form-label">
              Role
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="staticRole"
                value={this.state.user.role}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticType" className="col-sm-2 col-form-label">
              Type
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="staticType"
                value={this.state.user.userType}
              />
            </div>
          </div>
        </form>
      </div>
  */

/*
<div className="card" style={{ width: "18rem" }}>
          <img
            className="card-img-top"
            src=".../100px180/?text=Image cap"
            alt="Card image cap"
          />
          <div className="card-body">
            <p className="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
  */
