import http from "./httpService";
import { configuration } from "../globals";

const base_url = `${configuration.vt_server_address}:${
  configuration.vt_server_port
}/api/technicianPassword`;

export async function updateTechnicianPassword(oldPassword, newPassword) {
  return http.put(`${base_url}`, { oldPassword, newPassword });
}
