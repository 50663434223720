/**
 * @fileOverview Functionality to call the user registration endpoint.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

import http from "./httpService";
import { configuration } from "../globals";

const base_url = `${configuration.vt_server_address}:${
  configuration.vt_server_port
}/api/users`;

/**
 * Calls the user registration endpoint.
 * @param {string} firstname First name.
 * @param {string} lastname Last name.
 * @param {string} username Username.
 * @param {string} password Password.
 * @param {string} role The user's role.
 * @param {string} userType The user's type.
 */
export function registerUser(
  firstname,
  lastname,
  username,
  password,
  role,
  userType
) {
  return http.post(`${base_url}/register_user`, {
    firstname,
    lastname,
    username,
    password,
    role,
    userType
  });
}
