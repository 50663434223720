/**
 * @fileOverview The register patient page.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React from "react";
import Joi from "joi-browser";

// VT Touch
import Form from "./common/Form";
import { registerPatient } from "./../services/patients";

class RegisterPatienForm extends Form {
  state = {
    data: {
      firstname: "",
      lastname: "",
      medicalID: "",
      deviceID: ""
    },
    user: this.props.user,
    errors: {}
  };

  schema = {
    firstname: Joi.string()
      .required()
      .max(50)
      .label("First Name"),
    lastname: Joi.string()
      .required()
      .max(50)
      .label("Last Name"),
    medicalID: Joi.string()
      .required()
      .max(50)
      .label("Medical ID"),
    deviceID: Joi.string()
      .required()
      .max(50)
      .label("Device ID")
  };

  doSubmit = async () => {
    const { data } = this.state;

    try {
      const { data: result } = await registerPatient(
        data.firstname,
        data.lastname,
        data.medicalID,
        this.state.user.username,
        data.deviceID
      );
      window.location = "/patients";
      //this.props.history.push("/"); // This does not seem to force a reload.
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.deviceID = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="container">
        <p />
        <h4>
          <span className="badge badge-secondary">Register Patient</span>
        </h4>
        <div className="shadow p-3 mb-3 bg-white rounded">
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("firstname", "First Name")}
            {this.renderInput("lastname", "Last Name")}
            {this.renderInput("medicalID", "Medical ID")}
            {this.renderInput("deviceID", "Device ID")}

            <p />

            {this.renderButton("Register")}
          </form>
        </div>
      </div>
    );
  }
}

export default RegisterPatienForm;
