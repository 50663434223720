/**
 * @fileOverview Displays patients and associated data.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React from "react";
import MaterialTable from "material-table";
import axios from "axios";
import Joi from "joi-browser";

// Material UI
import CloudUpload from "@material-ui/icons/CloudUpload";

// VT Touch
import { configuration } from "../globals";
import Patient from "./Patient";
import { updatePatient } from "./../services/patients";

const base_url = `${configuration.vt_server_address}:${configuration.vt_server_port}/api/patients`;

class Patients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "First Name", field: "firstname" },
        { title: "Last Name", field: "lastname" },
        { title: "Medical ID", field: "medicalID", editable: "onAdd" },
        { title: "Device ID", field: "deviceID" }
      ],
      data: [],
      user: this.props.user,
      selectedPatient: null,
      selectedAction: null,
      schema: {
        firstname: Joi.string()
          .required()
          .max(50)
          .label("First Name"),
        lastname: Joi.string()
          .required()
          .max(50)
          .label("Last Name"),
        deviceID: Joi.string()
          .required()
          .max(50)
          .label("Device ID")
      }
    };
  }

  async componentDidMount() {
    try {
      if (this.state.user !== null) {
        const data = await this.getPatients();
        this.setState({ data });
      }
    } catch (e) {}
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.user && !this.props.user) {
      return;
    } else if (prevProps.user && !this.props.user) {
      this.setState({ user: this.props.user });
    } else if (!prevProps.user && this.props.user) {
      const data = await this.getPatients();
      this.setState({ user: this.props.user, data: data });
    } else if (prevProps.user.username !== this.props.user.username) {
      const data = await this.getPatients();
      this.setState({ user: this.props.user, data: data });
    }
  }

  async getPatients() {
    const { data: patients } = await axios.get(
      `${base_url}/${this.props.user.username}`
    );

    return patients.map(patient => {
      return {
        firstname: patient.firstname,
        lastname: patient.lastname,
        medicalID: patient.medicalID,
        deviceID: patient.deviceID
      };
    });
  }

  validate(data) {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, this.state.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  }

  render() {
    return (
      <>
        <div className="container-fluid mt-3">
          <MaterialTable
            title="Patients"
            columns={this.state.columns}
            data={this.state.data}
            options={{
              rowStyle: rowData => ({
                backgroundColor:
                  this.state.selectedRow &&
                  this.state.selectedRow.tableData.id === rowData.tableData.id
                    ? "#EEE"
                    : "#FFF"
              })
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(async () => {
                    {
                      const data = this.state.data;
                      const index = data.indexOf(oldData);
                      data[index] = newData;
                      console.log(JSON.stringify(newData));
                      try {
                        // Validate the data.
                        const errors = this.validate({
                          firstname: newData.firstname,
                          lastname: newData.lastname,
                          deviceID: newData.deviceID
                        });

                        if (errors) {
                          alert("Error: " + JSON.stringify(errors));
                        }
                        await updatePatient(
                          newData.firstname,
                          newData.lastname,
                          newData.medicalID,
                          "no-update",
                          newData.deviceID
                        );
                        this.setState({ data }, () => resolve());
                      } catch (ex) {
                        if (ex.response && ex.response.status === 400) {
                          alert(
                            "Received error response from server: " +
                              ex.response.data
                          );
                        }
                      }
                    }
                    resolve();
                  }, 1000);
                })
            }}
            actions={[
              {
                // Action
                // This action shows a listing of logs associated
                // with the selected patient.
                icon: "list",
                tooltip: "Show Logs",
                onClick: (event, rowData) => {
                  // Build a patient object that makes sense
                  // to the Patient component.
                  const selectedPatient = {
                    firstname: rowData.firstname,
                    lastname: rowData.lastname,
                    medicalID: rowData.medicalID
                  };
                  this.setState({
                    selectedPatient: selectedPatient,
                    selectedAction: "show_logs",
                    selectedRow: rowData
                  });
                }
              }
            ]}
          />
        </div>
        <div className="container-fluid mt-3 pb-3">
          <Patient
            patient={this.state.selectedPatient}
            selectedAction={this.state.selectedAction}
          />
        </div>
      </>
    );
  }
}

export default Patients;
