/**
 * @fileOverview Manages device log uploading.
 * @author Glue Architectures, Inc.
 *
 * Date: 2020-01-26
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React, { Component } from "react";

// Material UI
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import SvgIcon from "@material-ui/core/SvgIcon";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import UploadImage from "./baseline-cloud_upload-24px.svg";

// VT Touch
import { configuration } from "../../globals";
import http from "../../services/httpService";
import "./DeviceLogUpload.css";

const base_url = `${configuration.vt_server_address}:${configuration.vt_server_port}/api/devices/log`;

class DeviceLogUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hightlight: false,
      files: [],
      displayedFiles: []
    };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.getFilesFromDataTransferItems = this.getFilesFromDataTransferItems.bind(
      this
    );
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    console.log("files: " + files);
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.props.disabled) return;
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  async onDrop(event) {
    event.preventDefault();

    if (this.props.disabled) return;

    // A "better?" implementation.
    const listing = await this.getFilesFromDataTransferItems(
      event.dataTransfer.items
    );

    // Filter list.
    const filteredList = listing.filter(file => {
      // Files must:
      // 1) Not begin with a ".".
      // 2) Must have .json as an extension.
      if (file.name.charAt(0) === ".") {
        return false;
      }

      // Obtain file extention.
      let fileExtension = file.name.slice(
        (Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1
      );
      if (fileExtension.toUpperCase() !== "JSON") {
        return false;
      }

      // Ok, if here, we'll accept the file.
      return true;
    });

    const displayedFiles = [];
    filteredList.forEach((file, i) => {
      displayedFiles.push({ name: file.name, state: -1 });
    });

    this.setState({
      hightlight: false,
      files: this.state.files.concat(filteredList),
      displayedFiles: this.state.displayedFiles.concat(displayedFiles)
    });

    return;
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }

    return array;
  }

  async getFilesFromDataTransferItems(
    dataTransferItems,
    options = { raw: false }
  ) {
    // Error function.
    const checkErr = err => {
      if (this.getFilesFromDataTransferItems.didShowInfo) return;
      if (err.name !== "EncodingError") return;

      this.getFilesFromDataTransferItems.didShowInfo = true;
      const infoMsg =
        `${err.name} occured within datatransfer-files-promise module\n` +
        `Error message: "${err.message}"\n` +
        "Try serving html over http if currently you are running it from the filesystem.";
      console.warn(infoMsg);
    };

    const readFile = (entry, path = "") => {
      return new Promise((resolve, reject) => {
        entry.file(
          file => {
            if (!options.raw) file.filepath = path + file.name; // Save full path.
            resolve(file);
          },
          err => {
            checkErr(err);
            reject(err);
          }
        );
      });
    };

    const dirReadEntries = (dirReader, path) => {
      return new Promise((resolve, reject) => {
        dirReader.readEntries(
          async entries => {
            let files = [];
            for (let entry of entries) {
              const itemFiles = await getFilesFromEntry(entry, path);
              files = files.concat(itemFiles);
            }
            resolve(files);
          },
          err => {
            checkErr(err);
            reject(err);
          }
        );
      });
    };

    const readDir = async (entry, path) => {
      const dirReader = entry.createReader();
      const newPath = path + entry.name + "/";
      let files = [];
      let newFiles;
      do {
        newFiles = await dirReadEntries(dirReader, newPath);
        files = files.concat(newFiles);
      } while (newFiles.length > 0);
      return files;
    };

    const getFilesFromEntry = async (entry, path = "") => {
      if (entry.isFile) {
        const file = await readFile(entry, path);
        return [file];
      }
      if (entry.isDirectory) {
        const files = await readDir(entry, path);
        return files;
      }
    };

    let files = [];
    let entries = [];

    // Pull out all entries before reading them
    for (let i = 0, ii = dataTransferItems.length; i < ii; i++) {
      entries.push(dataTransferItems[i].webkitGetAsEntry());
    }

    // Recursively read through all entries
    for (let entry of entries) {
      const newFiles = await getFilesFromEntry(entry);
      files = files.concat(newFiles);
    }

    return files;
  }

  onClickHandler = async () => {
    for (var x = 0; x < this.state.files.length; x++) {
      try {
        // Read the file.
        let result = JSON.parse(await readFileAsync(this.state.files[x]));

        // Post to the server.
        await http.post(`${base_url}`, result);

        // If here, we are successful.
        const displayedFiles = this.state.displayedFiles;
        displayedFiles[x] = { name: displayedFiles[x].name, state: 0 };
        this.setState({ displayedFiles: displayedFiles });
      } catch (error) {
        // Some kind of error occured.
        const displayedFiles = this.state.displayedFiles;
        displayedFiles[x] = {
          name: `Error- ${displayedFiles[x].name}`,
          state: 1
        };
        this.setState({ displayedFiles: displayedFiles });
      }
    }
  };

  render() {
    return (
      <div className="DropzoneWrapper">
        <br />
        <div
          className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          style={{ cursor: this.props.disabled ? "default" : "pointer" }}
        >
          <input
            ref={this.fileInputRef}
            className="FileInput"
            type="file"
            multiple
            onChange={this.onFilesAdded}
            mozdirectory="true"
            directory="true"
          />
          <img alt="upload" className="Icon" src={UploadImage} />
          <span>Upload Files</span>
        </div>
        <div>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={this.onClickHandler}
          >
            Upload
          </Button>
        </div>
        <div className="FileWrapperlist">
          <div className="Filelist">
            {this.state.displayedFiles.length === 0 && (
              <React.Fragment>Drop Files in Dropzone</React.Fragment>
            )}
            {this.state.displayedFiles.length !== 0 && (
              <React.Fragment>
                <List dense={true}>
                  {this.state.displayedFiles.map((file, index) => {
                    let displayString = "";
                    if (file.length > 100) {
                      displayString = file.substring(0, 100) + "...";
                    } else {
                      displayString = file;
                    }
                    switch (file.state) {
                      case 0:
                        return (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <Check style={{ color: green[500] }} />
                            </ListItemIcon>
                            <ListItemText primary={displayString.name} />
                          </ListItem>
                        );
                      case 1:
                        return (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <Error style={{ color: red[500] }} />
                            </ListItemIcon>
                            <ListItemText primary={displayString.name} />
                          </ListItem>
                        );
                      default:
                        return (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <ArrowUpward />
                            </ListItemIcon>
                            <ListItemText primary={displayString.name} />
                          </ListItem>
                        );
                    }
                  })}
                </List>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsText(file);
  });
}

function Check(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
    </SvgIcon>
  );
}

function Error(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
    </SvgIcon>
  );
}

export default DeviceLogUpload;
