/**
 * @fileOverview Functionality to call the patient registration endpoint.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

import http from "./httpService";
import { configuration } from "../globals";

const base_url = `${configuration.vt_server_address}:${
  configuration.vt_server_port
}/api/patients`;

/**
 * Calls the patient registration endpoint.
 * @param {string} firstname
 * @param {string} lastname
 * @param {string} medicalID
 * @param {string} clinician
 */
export function registerPatient(
  firstname,
  lastname,
  medicalID,
  clinician,
  deviceID
) {
  return http.post(`${base_url}/register_patient`, {
    firstname,
    lastname,
    medicalID,
    clinician,
    deviceID
  });
}

/**
 * Calls the patient update endpoint.
 * @param {string} firstname
 * @param {string} lastname
 * @param {string} medicalID
 * @param {string} clinician
 */
export function updatePatient(
  firstname,
  lastname,
  medicalID,
  clinician,
  deviceID
) {
  return http.put(`${base_url}/update_patient`, {
    firstname,
    lastname,
    medicalID,
    clinician,
    deviceID
  });
}
