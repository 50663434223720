/**
 * @fileOverview Functionality to call the login endpoint.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

import jwtDecode from "jwt-decode";
import http from "./httpService";
import { configuration } from "../globals";

const base_url = `${configuration.vt_server_address}:${
  configuration.vt_server_port
}/api/auth`;
const tokenKey = "vt_token";

// Set the header for logged in users.
http.setJWT(getJWT());

/**
 * Calls the login endpoint.
 * @param {string} username The user's username.
 * @param {string} password The user's password.
 */
export async function login(username, password) {
  const { data: jwt } = await http.post(base_url, { username, password });
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  const jwt = localStorage.getItem(tokenKey);
  try {
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJWT() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  logout,
  getCurrentUser
};
