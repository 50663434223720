/**
 * @fileOverview Provides navigation bar functionality.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React, { Component } from "react";

// VT Touch
import { Link, NavLink } from "react-router-dom";
import logo from "../Synergic Final logo.svg";

/**
 * The navigation bar.
 * Note: This contains some work arounds for Bootstrap's reliance on JQuery.
 * We'll fix this in future versions of the UI.
 */
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      user: this.props.user,
      collapsed: true
    };
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user && !this.props.user) {
      return;
    } else if (!prevProps.user && this.props.user) {
      this.setState({ user: this.props.user });
    } else if (prevProps.user && !this.props.user) {
      this.setState({ user: this.props.user });
    } else if (prevProps.user.username !== this.props.user.username) {
      this.setState({ user: this.props.user });
    }
  }

  render() {
    console.log(JSON.stringify(this.state.user));
    const collapsed = this.state.collapsed;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";

    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to="/">
          <img src={logo} width="100" height="50" alt="" />
        </Link>
        <button
          onClick={this.toggleNavbar}
          className={`${classTwo}`}
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className={`${classOne}`} id="navbarNavAltMarkup">
          <div className="navbar-nav mr-auto">
            {this.state.user && this.state.user.role === "Admin" && (
              <React.Fragment>
                <NavLink className="nav-item nav-link" to="/users">
                  <u>Users</u> <span className="sr-only">(current)</span>
                </NavLink>
                <NavLink className="nav-item nav-link" to="/registeruser">
                  <u>Register User</u>{" "}
                  <span className="sr-only">(current)</span>
                </NavLink>
                <NavLink className="nav-item nav-link" to="/technicianPassword">
                  <u>Change Technician Password</u> <span className="sr-only">(current)</span>
                </NavLink>
              </React.Fragment>
            )}
            {this.state.user && this.state.user.role === "Clinician" && (
              <React.Fragment>
                <NavLink className="nav-item nav-link" to="/patients">
                  <u>Patients</u> <span className="sr-only">(current)</span>
                </NavLink>
                <NavLink className="nav-item nav-link" to="/registerpatient">
                  <u>Register Patient</u>{" "}
                  <span className="sr-only">(current)</span>
                </NavLink>
                <NavLink className="nav-item nav-link" to="/devicelogupload">
                  <u>Upload Log</u> <span className="sr-only">(current)</span>
                </NavLink>
              </React.Fragment>
            )}
          </div>
          <div className="navbar-nav ml-auto">
            {!this.state.user && (
              <React.Fragment>
                <NavLink className="nav-item nav-link" to="/login">
                  <u>Login</u> <span className="sr-only">(current)</span>
                </NavLink>
              </React.Fragment>
            )}
            {this.state.user && (
              <React.Fragment>
                <div className="navbar-nav ml-auto">
                  <NavLink className="nav-item nav-link" to="/logout">
                    <button type="button" className="btn btn-primary btn-sm">
                      Logout
                    </button>
                    <span className="sr-only">(current)</span>
                  </NavLink>
                  <NavLink className="nav-item nav-link" to="/profile">
                    <u>{`${this.state.user.firstname} ${this.state.user.lastname}`}</u>
                  </NavLink>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
