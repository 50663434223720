import React from 'react';
import Joi from 'joi-browser';
import { CircularProgress } from '@material-ui/core';

import Form from './common/Form';
import { updateTechnicianPassword } from '../services/technicianPassword';

class TechnicianPasswordForm extends Form {
  state = {
    data: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    errors: {},
    submitting: false,
    errorMessage: '',
    succeeded: false,
  }

  schema = {
    oldPassword: Joi.string()
      .allow('')
      .label('Old Password'),
    newPassword: Joi.string()
      .required()
      .min(16)
      .label('New Password'),
    confirmNewPassword: Joi.string()
      .required()
      .label('Confirm New Password')
  }

  doSubmit = async () => {
    this.setState({ errorMessage: '' });
    this.setState({ succeeded: false });
    const { oldPassword, newPassword, confirmNewPassword } = this.state.data;
    if (newPassword !== confirmNewPassword) {
      this.setState({ errors: { ...this.state.errors, confirmNewPassword: 'Passwords do not match' }});
      return;
    }
    this.setState({ submitting: true });
    try {
      await updateTechnicianPassword(oldPassword, newPassword);
      this.setState({ succeeded: true });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        this.setState({ errorMessage: 'Invalid old password' })
      } else if (err.response && err.response.data) {
        this.setState({ errorMessage: err.response.data.error });
      } else {
        this.setState({ errorMessage: err.toString() })
      }
    } finally {
      this.setState({ submitting: false });
    }
  }

  render() {
    return (
      <div className="container">
        <p />
        <h4>
          <span className="badge badge-secondary">Change Technician Password</span>
        </h4>
        <div className="shadow p-3 mb-3 bg-white rounded">
          <form onSubmit={this.handleSubmit}>
            {this.renderInput('oldPassword', 'Old Password', 'password')}
            {this.renderInput('newPassword', 'New Password', 'password')}
            {this.renderInput('confirmNewPassword', 'Confirm New Password', 'password')}
            <p />
            {this.state.submitting ? (
              <CircularProgress />
            ) : (
              this.renderButton("Register")
            )}
          </form>
        </div>
        {this.state.succeeded && (
          <div className="alert alert-success">Password successfully updated!</div>
        )}
        {this.state.errorMessage && (
          <div className="alert alert-danger">{this.state.errorMessage}</div>
        )}
      </div>
    )
  }
}

export default TechnicianPasswordForm;
